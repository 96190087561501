@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Yellowtail&display=swap');


:root {
  --montserrat-sans-serif: 'Montserrat', sans-serif;
  --secondary-font-some-words: 'Yellowtail', cursive;
  --general-bg-color: #F0F0F0;
  --color-white: white;
  --color-black: black;
  --color-transparent: transparent;
  --casa-orange: #f78812;
  --big-gray-cards: #252525;
  --badge-font-color: #5E7375;
  --color-lightgray: lightgray;
  --text-lightgray: #b8b8b8;
  --font-weight-lighter: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

}

body {
  cursor: url("./images/icons/sal-_1_.webp"), auto !important;
}

a {
  text-decoration: none;
  color: inherit
}

html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar { 
  display: none;
}

body {
  background-color: var(--general-bg-color)
}

a, h1, h2, h3, h4, h5, h6, p, input, textarea, label, button {
  font-family: var(--outfit-sans-serif);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: var(--font-weight-regular);
  margin: 0;
}

strong {
  color: var(--casa-orange);
  font-weight: inherit;
}

.section-container {
  width: 100%;
  min-height: 500px;
}

.section-margin {
  margin: 100px 0;
}

.fit-content {
  width: fit-content;
}

.section-padding {
  padding: 40px 0;
}

.simple-flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
}


.flex-col {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

.center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.justify-center-text {
  text-align: center;
}

.svg-next-container {
  background-color: #f0f0f0;
  position: relative;
  top: -2px;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

/* ------------------------------------------- Header ---------------------------------------------- */

.header-container, .header-container-scroll {
  width: 100%;
  position:fixed;
  min-height: fit-content;
  z-index: 99;
}

.static-header {
  width: 100%;
  min-height: fit-content;
  top: 0;
  z-index: 99;
}

.header-container {
  background-color: var(--color-transparent);
}

.header-container-scroll, .static-header {
  background-color: var(--general-bg-color);
  border: none;
  border-bottom: 1px solid lightgray;
}

.header-align {
  padding: 10px 65px;
}

.each-link, .scroll-link {
  display: inline-block;
  font-weight:var(--font-weight-medium);
  position:relative;
  cursor: pointer;
}

.each-link {
  color: var(--casa-orange);
}

.scroll-link {
 color: var(--color-black);
}

.each-link::after, .scroll-link::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}
.each-link::after {
  background-color: var(--casa-orange);
}

.scroll-link::after {
  background-color: var(--color-black);
}

.each-link:hover::after, .scroll-link:hover::after {
  transform: scaleX(1);
}

.logo-header-container, .general-icons-dimensions {
  width: 80px;
  height: 80px;
}

.logo-header-container-scroll {
  width: 60px;
  height: 60px;
}

.small-logo-header {
  width: 24px;
  height: 24px;
}

.logo-header {
  width: 100%;
  aspect-ratio: 1 / 1;
}

.align-logo-center {
  position: relative;
  left: -40px;
}

.header-containers-gap {
  gap: 10%;
}

.icons-svg-dimensions {
  color: var(--casa-orange);
  font-size: 24px;
}

.icons-svg-scroll {
  color: var(--color-black);
  font-size: 24px;
}

.icon-link:hover {
  position: relative;
  top: -5px;
}

.contact-btn {
  z-index: 1;
  padding: 10px 12px;
  position: relative;
  border-radius: 20px 0;
  display: inline-block;
  transition: 0.3s ease all;
  font-weight: var(--font-weight-semibold);
  color: var(--casa-orange);
  border: 3px solid var(--casa-orange);
}

.contact-btn:hover,  
.contact-btn:focus {
  color: var(--color-white);
}

.contact-btn:before {
  top: 0;
  left: 50%;
  bottom: 0;
  opacity: 0;
  right: 50%;
  content: '';
  z-index: -1;
  position: absolute;
  border-radius: 16px 0;
  transition: 0.5s all ease;
}

.contact-btn:before {
  background-color: var(--casa-orange);
}

.contact-btn:hover:before, 
.contact-btn:focus:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.contact-btn:active {
  transform: scale(0.9);
}

.scroll-contact-btn {
  z-index: 1;
  padding: 10px 12px;
  position: relative;
  border-radius: 20px 0;
  display: inline-block;
  transition: 0.3s ease all;
  font-weight: var(--font-weight-semibold);
  color: var(--color-black);
  border: 3px solid var(--color-black);
}

.scroll-contact-btn:hover,  
.scroll-contact-btn:focus {
  color: var(--color-white);
}

.scroll-contact-btn:before {
  top: 0;
  left: 50%;
  bottom: 0;
  opacity: 0;
  right: 50%;
  content: '';
  z-index: -1;
  position: absolute;
  border-radius: 16px 0;
  transition: 0.5s all ease;
}

.scroll-contact-btn:before {
  background-color: var(--color-black);
}



.scroll-contact-btn:hover:before, 
.scroll-contact-btn:focus:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.scroll-contact-btn:active {
  transform: scale(0.9);
}

.mobile-show {
  display: none
}

/* ---------------------------------------------- sidebar ---------------------------------------------- */

.sidebar, .right-sidebar {
  position: fixed;
  top: 0;
  z-index: 9999;
  display: flex;
  transition: 0.5s;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--casa-orange);
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
}

.sidebar {
  left: -100%;
}

.sidebar.active{
  left: 0;
}

.right-sidebar {
  right: -100%;
}

.right-sidebar.active {
  right: 0;
}

.sidebar-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  backdrop-filter: blur(3px);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
}
.sidebar-overlay.active{
  opacity: 1;
  visibility: visible;
}

.sidebar-search, .sidebar-menu {
  padding: 60px 0;
  width: 35%;
  gap: 2em;
}

.sidebar-search-logo {
  width: 220px;
}

.submit-button {
  width: 50px;
  height: 50px;
  font-size: 24px;
  border-radius: 10px;
  color: var(--casa-orange);
  border: 2px solid var(--color-black);
  background-color: var(--color-black);
  transition: all .3s ease;
}

.submit-button:hover {
  transform: scale(1.06);
}

.search-input {
  width: 65%;
  outline: none;
  padding: .2rem 0;
  min-height: 40px;
  line-height: 28px;
  border: 2px solid transparent;
  border-bottom-color:var(--color-black);
  font-family: var(--montserrat-sans-serif);
  background-color: var(--color-transparent);
  transition: .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

input.search-input:focus, input.search-input:hover {
  outline: none;
  padding: .2rem 1rem;
  border-radius: 10px;
  border-color: var(--color-black);
 }

 input.search-input::placeholder {
  color: var(--big-gray-cards);
  opacity: .7;
 }

 .search-input:focus::placeholder {
  opacity: 0;
  transition: opacity .3s;
 }

 .search-form {
  gap: 20px;
  padding: 0 25px;
 }
 
.menu-component {
  gap: 15px;
  width: fit-content;
  margin: 0 auto;
  text-align: center;
}

.sidebar-link {
  font-size: 28px;
  font-weight: var(--font-weight-medium);
  color: var(--color-black);
}

.sidebar-link:hover {
  font-weight: var(--font-weight-semibold);
  position: relative;
  top: -5px;
}

div.hide-desktop {
  display: none;
}


 
/* ---------------------------------------------- intro ------------------------------------------------- */

.bg-fit {
  background-size: cover;
}

.intro-container {
  width: 100%;
  height: 116vh;
  position: relative;
}

.intro-title {
  color: var(--color-white);
  margin: 0;
  font-size: 64px;
  font-weight: var(--font-weight-bold);
}

.content-box-intro {
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
  top: 19%;
  left: 100px;
}

.los {
  margin: 0;
  rotate: -30deg;
  font-size: 48px;
  position: relative;
  left: 10px;
  color: var(--casa-orange);
  font-weight: var(--font-weight-regular);
  font-family: var(--secondary-font-some-words);
}
.custom-shape-divider-bottom-1681072197 {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1681072197 svg {
  position: relative;
  display: block;
  width: calc(124% + 1.3px);
  height: 95px;
}

.custom-shape-divider-bottom-1681072197 .shape-fill {
  fill: #F0F0F0;
}

/* ------------------------------------------ footer ------------------------------------------------ */

.footer {
  padding: 60px 80px;
}

.footer-logo {
  width: 20%;
}

.footer-link-title {
  font-family: var(--montserrat-sans-serif);
  font-weight: var(--font-weight-bold);
  color: var(--color-black);
  font-size: 24px;
  margin: 5px 0;
}

.footer-menu-link, .bottom-info-site {
  font-family: var(--montserrat-sans-serif);
  font-weight: var(--font-weight-medium);
  color: var(--color-black);
  opacity: .65;
}

.bottom-info-site {
  font-size: 14px;
}

.footer-menu-link {
  font-size: 16px;
  display: flex;
  gap: 7px;
  cursor: pointer;
}

.footer-menu-link:hover {
  opacity: 1;
  position: relative;
  top: -2.5px;
}

.logo-footer {
  width: 16px;
  height: 16px;
}

.direction-max-width {
  max-width: 290px;
}

.line-height {
  line-height: 25px;
}

.flex-footer {
  display: flex;
  justify-content: space-between;
}

.footer-top-publicity {
  width: 100%;
  display: grid;
  place-items: center;
  height: 500px;
  margin: 60px 0 0 0;
  background-size: cover;
}

.footer-top-publicity::before {
  content: '';
  display: block;
  position: absolute;
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: 500px;
}

.publicity-title {
  width: 80%;
  position: absolute;
  text-align: center;
}

.footer-menu-box {
  gap: 10px;
  width: fit-content;
}

.padding-menu {
  padding: 0 25px;
}

/* ------------------------------------------ first section ----------------------------------------- */

.quotes-container {
  border-radius: 50%;
  background-color: rgba(247, 136, 18, .6);
  width: 80px;
  height: 80px;
  display: grid;
  place-items: center;
  position: relative;
  top: -3em;
  left: 1em;
}

.quotes {
  width: 60%;
  height: 60%;
}

.business-frase {
  margin:0;
  max-width: 60%;
  font-size: 32px;
  font-weight: var(--font-weight-bold);
  position: relative;
  z-index: 9;
}

.big-title {
  font-weight: var(--font-weight-bold);
  font-size: 48px;
  max-width: 60%;
}

.underline {
  height: 30px;
  width: 260px;
  position: relative;
  left: -12%;
}

.message-slider {
  max-width: 90%;
  font-size: 16px;
  text-align: center;
  font-weight: var(--font-weight-medium);
}

.slider-container {
  width: 75%;
}

.first-slider-card {
  border-radius: 30px;
  height: 290px;
  width: 215px;
  background-size: cover;
}

.casa-btn {
  position: relative;
  z-index: 9;
  padding: 8px 18px;
  border-radius: 20px 0;
  display: inline-block;
  transition: 0.8s all ease;
  color: var(--color-white);
  border: 2px solid var(--casa-orange);
  background-color: var(--casa-orange);
  font-weight: var(--font-weight-semibold);
  margin: 25px 0;
}

.casa-btn:hover, .casa:focus {
  border: 2px solid var(--color-black)
}

.casa-btn:before {
  top: 0;
  left: 50%;
  bottom: 0;
  opacity: 0;
  right: 50%;
  content: '';
  z-index: -1;
  position: absolute;
  border-radius: 16px 0;
  transition: 0.5s all ease;
  background-color: var(--color-black);
}

.casa-btn:hover:before, .casa:focus:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.casa-btn:active {
  transform: scale(0.9);
}

/* ------------------------------------------ categories -------------------------------------------- */

.categories-warning, .featured-title {
  color: var(--color-black);
  font-size: 24px;
  font-weight: var(--font-weight-medium);
  text-align: center;
}

.categories-info {
  color: var(--color-black);
  opacity: .52;
}

.categories-text-align, .categories-text-padding {
  align-items: baseline;
  gap: 10px;
  position: absolute;
  left: calc(0% + 5vw);
}

.categories-text-align {
  top: -30px;
}

.categories-text-padding {
  top: 20px;
}

.categories-link-container {
  width: 128px;
  height: 150px;
  display: flex;
  gap: 5px;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-white);
  font-weight: var(--font-weight-medium);
  font-size: 16px;
}

.categories-link-container:hover {
  position: relative;
  top: -10px;
  box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.25);
}

.categories-img {
  width: 70px;
  height: 70px;
}

.categories-name-text {
  max-width: 60%;
  text-align: center;
  margin: 0;
}

.categories-container {
  gap: 15px;
  margin: 20px 0;
}

.categories-section-margin {
  margin: 50px 0;
}

.categories-section-padding {
  padding: 50px 0 0;
  position: relative;
  top: -5px;
  background-color: var(--general-bg-color);
}

.categories-mobile-container {
  display: none;
  width: 90%;
  margin: 20px 0;
}

.categories-mobile-circle {
  width: 100px;
  height: 100px;
  display: grid;
  border-radius: 50%;
  place-items: center;
  background-color: var(--color-white);
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
}

.categories-mobile-img{
  width: 65%;
}

.categories-mobile-text {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  text-align: center;
}

.categories-mobile-box {
  gap: 5px;
  max-width: 140px;
}

/*  ----------------------------------------- promotion --------------------------------------------- */

.promotion-container {
  background-color: var(--big-gray-cards);
  height: fit-content;
  width: 65%;
  padding: 50px 0;
  border-radius: 0 20px 20px 0;
}

.promotion-content-container {
  gap: 7.5px;
}

.promo-icons {
  width: 75px;
  height: 75px;
}

.promo-img {
  width: 320px;
  height: 380px;
  border-radius: 20px;
  position: relative;
  left: -40px;
}

.figuretext-img {
  font-weight: var(--font-weight-semibold);
  font-size: 59px;
  color: var(--casa-orange);
}

figure {
  margin: 0;
}

figcaption {
  color: var(--color-white);
  font-size: 18px;
  font-weight: var(--font-weight-medium);
}

.promo-title {
  color: var(--color-white);
  font-weight: var(--font-weight-bold);
  font-size: 40px;
  max-width: 600px;
  line-height: 49px;
}

.promo-paragraph {
  font-size: 16px;
  max-width: 560px;
  color: var(--color-white);
 font-weight: var(--font-weight-medium);
}

.promo-flex-icons {
  width: 90%;
  max-width: 560px;
  margin: 15px 0;
}

.promo-btn {
  position: relative;
  z-index: 9;
  padding: 8px 18px;
  border-radius: 20px 0;
  display: inline-block;
  transition: 0.8s all ease;
  color: var(--color-white);
  border: 2px solid var(--casa-orange);
  background-color: var(--casa-orange);
  font-weight: var(--font-weight-semibold);
}

.promo-btn:hover, .promo:focus {
  border: 2px solid var(--color-white);
  color: var(--big-gray-cards);
}

.promo-btn:before {
  top: 0;
  left: 50%;
  bottom: 0;
  opacity: 0;
  right: 50%;
  content: '';
  z-index: -1;
  position: absolute;
  border-radius: 16px 0;
  transition: 0.5s all ease;
  background-color: var(--color-white);
}

.promo-btn:hover:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.promo-btn:active {
  transform: scale(0.9);
}

/* ------------------------------------------ Our business ------------------------------------------*/

.business-title {
  font-size: 32px;
  font-weight: var(--font-weight-semibold);
  color: var(--color-black);
  text-align: center;
}

.busines-title-underline {
  width: 325px;
}

.badge-relative-box {
  width: 230px;
  min-height: 265px;
  position: relative;
  border-radius: 20px;
  background-color: var(--color-white);
  box-shadow: 6px 5px 26px rgba(0, 0, 0, 0.25);
}

.business-badge-img {
  border-radius: 50%;
  box-shadow: 0px 0px 40px -2px #9DAFB0;
  width: 210px;
  height: 210px;
  position: absolute;
  top: -105px;
}

.business-small-img-container {
  width: 75px;
  height: 75px;
  display: grid;
  place-items: center;
  border-radius: 15px;
  background: var(--color-white);
  background-color: var(--color-white);
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 9;
}

.business-small-img {
  width: 45px;
  height: 45px;
}

.business-each-title {
  font-size: 20px;
  text-align: center;
  color: var(--color-black);
  font-weight: var(--font-weight-bold);
}

.business-content {
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--badge-font-color);
  opacity: .6;
  max-width: 190px;
  margin-top: 5px;
}

.badges-flex {
  display: flex;
  justify-content: center;
  gap: 2em;
  margin: 120px 0 0;
}

.badge-content-container {
  position: relative;
  gap: 7.5px;
  top: 10px;
}
/* ------------------------------------------ Featured Products ------------------------------------- */

.featured-product-card, .products-gallery-card {
  background-color: var(--color-white);
  box-shadow: 6px 6px 25px rgba(0, 0, 0, 0.16);
  border-radius: 9px;
  margin: 20px 0;
  width: 215px;
}

.products-gallery-card {
  min-height: 260px;
}

.featured-product-card {
  height: 300px;
}

.featured-product-card:hover, .products-gallery-card:hover {
  position: relative;
  top: -10px;
  box-shadow: 6px 6px 17px rgba(0, 0, 0, 0.47);
}

.products-gallery-card {
  height: fit-content;
    padding: 1em 0;
}

.product-image-container {
  display: flex;
  flex-direction: column;
  gap: 7.5px;
  align-items: center;
}

.product-img {
  width: 80%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.product-name, .product-price {
  font-weight: var(--font-weight-medium);
  color: var(--color-black);
  text-align: center;
}

.product-name {
  font-size: 20px;
}

.product-price {
  font-size: 16px;
  opacity: .6;
}

.title-container {
  position: relative;
  left: -30.5%;
}

.product-inner-text {
  max-width: 90%;
}

/* ------------------------------------------ Product page ------------------------------------------ */

.product-centered-text {
  height: 100vh;
  display: grid;
  place-items: center;
}

.alternative-product-title-position {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-centered-text::before {
  content: '';
  display: block;
  position: absolute;
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: 130vh;
}

.custom-shape-divider-bottom-1681522953 {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1681522953 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 95px;
}

.custom-shape-divider-bottom-1681522953 .shape-fill {
  fill: #F78812;
}

/* ------------------------------------------ Products container ------------------------------------ */

.Products-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 250px;
  top: -2px;
  padding-bottom: 40px;
  justify-content: center;
  background-color: var(--casa-orange);
}

.products-section-featured {
  background-color: var(--general-bg-color);
  padding: 60px 0;
  position: relative;
  top: -5px;
}

.products-flex {
  display: grid;
  flex-wrap: wrap;
  padding-bottom: 5em;
}

.products-flex a {
  display: flex;
  justify-content: center;
}

.gallery-products {
  margin: 1em;
}

.custom-shape-divider-bottom-1681526847 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1681526847 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 95px;
}

.custom-shape-divider-bottom-1681526847 .shape-fill {
  fill: #F0F0F0;
}

/* ------------------------------------------ Single Product ---------------------------------------- */

.single-product-page {
  display: flex;
  justify-content: space-between;
  margin: 2em 0;
}

.single-product-img {
  width:366px;
  height: 366px;
}

.single-product-img img {
  object-fit: cover;
  width: calc(100% - 30px);
  aspect-ratio: 1 / 1;
}

.horizontal-padding {
  padding: 0 1.5em;
}

.single-product-info {
  width: 40%;
  padding: 0 20px 0 10px;
}

.product-info-container h4 {
  text-transform: uppercase;
  color: var(--casa-orange);
  font-weight: var(--font-weight-semibold);
  font-size: 20px;
}

.product-info-container h1 {
  font-weight: var(--font-weight-semibold);
  color: var(--color-black);
  font-size: 36px;
  max-width: 90%;
}

.product-description, .price-description {
  font-size: 16px;
  color: #b8b8b8;
  font-weight: var(--font-weight-medium);
  margin: 1em 0;
}

.purchase-box-container {
  flex: 0.5 0 20%;
  margin: 2em 0;
}

.purchase-box {
  border: 1px solid var(--color-lightgray);
  border-radius: 25px;
  padding: 20px;
  height: fit-content;
  gap: 12px;
  width: 200px;
  max-width: 450px;
}

.cart-btn, .purchase-btn {
  border: 2px solid var(--casa-orange);
  font-size: 16px;
  font-weight: var(--font-weight-semibold);
  padding: 10px 15px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  display: inline-block;
  transition: 0.3s ease all;
}

.cart-btn {
  background-color: var(--color-transparent);
  color: var(--casa-orange);
}

.cart-btn:hover {
  border: 2px solid var(--color-lightgray);
  color: var(--color-black);
}

.cart-btn:before {
  top: 0;
  left: 50%;
  bottom: 0;
  opacity: 0;
  right: 50%;
  content: '';
  z-index: -1;
  position: absolute;
  border-radius: 7.5px;
  transition: 0.5s all ease;
  background-color: var(--color-lightgray);
}

.cart-btn:hover:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.cart-btn:active {
  transform: scale(0.9);
}

.purchase-btn {
  background-color: var(--casa-orange);
  color: var(--color-white);
}


.purchase-btn:hover {
  border: 2px solid var(--color-black);
}

.purchase-btn:before {
  top: 0;
  left: 50%;
  bottom: 0;
  opacity: 0;
  right: 50%;
  content: '';
  z-index: -1;
  position: absolute;
  border-radius: 7.5px;
  transition: 0.5s all ease;
  background-color: var(--color-black);
}

.purchase-btn:hover:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.purchase-btn:active {
  transform: scale(0.9);
}

.buttons-container {
  gap: 10px;
}

.mas-btn, .menos-btn {
  height: 34px;
  width: 34px;
  font-size: 18px;
  font-weight: var(--font-weight-semibold);
  border: none;
  transition: 0.3s ease all;
}

.mas-btn {
  color: var(--color-white);
  background-color: var(--casa-orange);
  border-radius: 0 10px 10px 0;
}

.menos-btn {
  color: var(--color-black);
  background-color: var(--color-lightgray);
  border-radius: 10px 0 0 10px;
}

.menos-btn:hover {
  background-color: rgb(211, 211, 211, .6);
}

.mas-btn:hover {
  background-color: rgba(247, 136, 18, .6);
}

.cantidad {
  height: 32px;
  width: 70%;
  border: none;
  background-color: #ebebeb;
  font-weight: var(--font-weight-semibold);
  font-size: 18px;
  color: var(--color-black);
  text-align: center;
}

.cantidad:focus {
  outline: none;
}

.price-spec {
  font-size: 16px;
  color: var(--color-black);
  font-weight: var(--font-weight-medium);
  opacity: .6;
}

.price-numbers {
  font-size: 16px;
  color: var(--color-black);
  font-weight: var(--font-weight-semibold);
}

.purchase-caption {
  color: var(--color-black);
  font-weight: var(--font-weight-medium);
  margin:0 0 5px;
  font-size: 16px;
}

.price-align {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.price-align hr {
  width: 100%;
  margin: 0;
  opacity: .3;
}

.price-description strong {
  color: var(--color-black);
  font-weight: var(--font-weight-semibold);
  font-size: 28px;
}

#mobile-gr {
  display: none;
}

.in-cart {
  gap: 5px;
  display: flex;
  font-size: 16px;
  font-style: italic;
  align-items: center;
  font-weight: var(--font-weight-regular);
}

/* ------------------------------------------ cart -------------------------------------------------- */

.cart-items-container {
  padding: 2em;
  width: clamp(650px, 70%, 900px);
  background-color: var(--color-white);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
}

.cart-boxes-container {
  display: flex;
  justify-content: center;
  padding: 1.5em;
  gap: 1em;
}

.cart-items-container .purchase-btn {
  display: none;
}

.checkout-cart-box {
  padding: 1em;
  width: clamp(270px, 20%, 340px);
  background-color: var(--color-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: fit-content;
  gap: 1em;
}

.checkout-cart-box .purchase-btn {
  max-width: 280px;
  font-size: 18px;
}

.cart-item-img {
  width: clamp(90px, 20%, 180px);
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.cart-items-box {
  min-height: 300px;
  width: 100%;
  border-top: 1px solid var(--color-lightgray);
  border-bottom: 1px solid var(--color-lightgray);
  margin: 10px 0;
}

.cart-item {
  border-top: 1px solid var(--color-lightgray);
  border-bottom: 1px solid var(--color-lightgray);
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 1em 0;
}

.cart-item-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
}

.cart-item-info {
  max-width: 450px;
}

.cart-title {
  font-size: 32px;
  font-weight: var(--font-weight-semibold);
}

.cart-box-price-label {
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  display: flex;
  align-items: flex-end;
}

.cart-item-info h4 {
  font-size: 24px;
  font-weight: var(--font-weight-regular);
  margin-bottom: 5px;
}

.cart-item-info p {
  font-size: 16px;
  font-weight: var(--font-weight-regular);
  color: var(--color-black);
  opacity: .5;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
}

.cart-item-price {
  font-size: 18px;
  font-weight: var(--font-weight-semibold);
}

.subtotal, .cart-items-total {
  font-size: 18px;
  font-weight: var(--font-weight-regular);
}

.cart-items-total {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: flex-end;
}

.cart-items-total strong {
  color: var(--color-black);
  font-weight: var(--font-weight-semibold);
  font-size: 20px;
}

.hidden-price {
  display: none;
}

.flex-cantidad {
  display: flex;
  gap: 15px;
  margin: 0 25px 0 10px;
}

.cantidad-btn {
  color: var(--color-white);
  background-color: var(--casa-orange);
  border-radius: 50%;
  padding: 5px 10px;
  border: none;
}

.cart-cantidad {
  max-width: fit-content;
  color: var(--color-black);
  font-size: 20px;
  font-weight: var(--font-weight-medium);
}

/* ------------------------------------------ search interface -------------------------------------- */

.search-img {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.search-result-img-container {
  flex: 0 0 30%;
  display: grid;
  place-items: center;
}

.search-results-container {
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow-y: scroll;
}

.search-result-link {
  width: 80%;
  padding: 0 10%;
  transition: .3s ease;
  transition-property: background-color;
}

.search-result-link:hover {
  background-color: #f77812;
 }

.search-results-container::-webkit-scrollbar {
  width: 0;
}

.search-results-flex-h {
  display: flex;
  gap: 1em;
  margin-top: -1px;
  margin-bottom: 0px;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  padding: 10px 0;
}

.search-name {
  font-weight: var(--font-weight-regular);
  font-size: 18px;
  font-style: italic;
}

.search-price {
  font-weight: var(--font-weight-semibold);
  font-size: 16px;
  font-style: italic;
}

.search-description {
  font-size: 14px;
  font-weight: var(--font-weight-regular);
  font-style: italic;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.search-empty-result {
  padding: 0 10%;
  font-size: 20px;
  font-style: italic;
  font-weight: var(--font-weight-lighter);
}


/* ------------------------------------------ cart checkbox ----------------------------------------- */

/* Hide the default checkbox */
.container input {
  display: none;
}

.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

/* Create a custom checkbox */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: #2196F300;
  border-radius: 0.25em;
  transition: all 0.25s;
}

/* When the checkbox is checked, add a orange background */
.container input:checked ~ .checkmark {
  background-color: var(--casa-orange);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  transform: rotate(0deg);
  border: 0.1em solid var(--color-lightgray);
  left: 0;
  top: 0;
  width: 1.05em;
  height: 1.05em;
  border-radius: 0.25em;
  transition: all 0.25s, border-width 0.1s;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  left: 0.45em;
  top: 0.25em;
  width: 0.25em;
  height: 0.5em;
  border-color: #fff0 white white #fff0;
  border-width: 0 0.15em 0.15em 0;
  border-radius: 0em;
  transform: rotate(45deg);
}

/* ------------------------------------------ switch ------------------------------------------------ */

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  cursor: pointer;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  border-radius: 20px;
  box-shadow: inset 0 0 0 2px #ccc;
  transition: background-color 0.3s ease-in-out;
}

.toggle-switch-handle {
  position: absolute;
  top: 3.5px;
  left: 5px;
  width: 17px;
  height: 17px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.toggle-switch::before {
  content: "";
  position: absolute;
  top: -25px;
  right: -25px;
  font-size: 12px;
  font-weight: bold;
  color: #aaa;
  text-shadow: 1px 1px #fff;
  transition: color 0.3s ease-in-out;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-handle {
  transform: translateX(30px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 3px var(--casa-orange);
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-background {
  background-color: var(--casa-orange);
  box-shadow: inset 0 0 0 2px var(--casa-orange);
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch:before {
  content: "On";
  color: var(--casa-orange);
  right: -10px;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-background .toggle-switch-handle {
  transform: translateX(25px);
}

/* ------------------------------------------ message ----------------------------------------------- */

.message-container {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--color-white);
  padding: 1em;
  width: 40%;
  display: flex;
  justify-content: flex-start;
  max-height: 3em;
  border-radius: 15px;
  align-items: center;
}

.message-container h3 {
  font-size: 24px;
  font-weight: var(--font-weight-regular);
  margin: 0;

}

/* ------------------------------------------ 404 page ---------------------------------------------- */

.father-container {
  height: 110vh;
  display: grid;
  place-items: center;
}

.father-container::before {
  content: '';
    display: block;
    position: absolute;
    background-color: #000;
    opacity: 0.5;
    width: 100%;
    height: 110vh;
}

.for0for-container {
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  position: absolute;
}

.for0for-container h2 {
  font-size: 100px;
  font-weight: var(--font-weight-regular);
  color: var(--casa-orange);
}

.for0for-container h4 {
  color: var(--color-white);
  font-size: 44px;
  font-weight: var(--font-weight-regular);
}


/* ------------------------------------------ media queries ----------------------------------------- */

@media only screen and (max-width:280px) {

  .intro-title {
    font-size: 28px;
  } 

  .los {
    font-size: 24px;
    top: -10px;
  }

  .quotes-container {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -2em;
    left: 0.5em;
  }

  .business-frase {
    max-width: 70%;
    line-height: 25px;
    font-weight: var(--font-weight-semibold);
    font-size: 18px;
  }

  .big-title {
    font-size: 20px;
    text-align: center;
    max-width: 90%;
  }

  .underline {
    width: 100px;
    left: -16%;
    height: auto;
  }

  .first-slider-card {
    width: 190px;
    height: 260px;
  }

  .categories-info {
    display: none;
  }

  .featured-product-card {
    width: 195px;
    height: 250px;
  }

  .z-fold-max-width {
    max-width: 200px;
  }

  #mail {
    display: none;
  }

  .footer-menu-link {
    font-size: 15px;
  }

  .promo-title, .promo-paragraph {
    max-width: 90%;
  }

  div.promo-flex-icons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
  }

  .mobile-menu-icon {
    width: 40px;
    height: 40px;
  }

  .mobile-header-icons, .mobile-scroll-menu {
    width: 30px;
    height: 30px;
  }


}

@media only screen and (min-width:281px) and (max-width:463px) {
  .intro-title {
    font-size: 36px;
  } 

  .los {
    font-size: 32px;
  }

  .content-box-intro {
    top: 23%;
  }

  .mobile-menu-icon {
    width: 50px;
    height: 50px;
  }

  .quotes-container {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -2em;
    left: 2em;
  }

  .underline {
    left: -15%;
    width: 128px;
    height: auto;
  }

  .big-title {
    font-size: 24px;
    max-width: 80%;
  }

  .message-slider {
    max-width: 80%;
  }

  .first-slider-card {
    width: 135px;
    height: 210px;
  }

  .categories-mobile-circle {
    width: 70px;
    height: 70px;
  }


  .categories-mobile-text {
    font-size: 14px;
  }

  .business-frase {
    max-width: 70%;
    line-height: 25px;
    font-weight: var(--font-weight-semibold);
    font-size: 20px;
  }

  .categories-warning {
    font-size: 20px;
  }

  .featured-product-card {
    width: 135px;
    height: 195px;
  }

  .mobile-header-icons {
    width: 40px;
    height: 40px;
  }

  .mobile-scroll-menu {
    width: 40px;
    height: 40px;
  }

  .products-gallery-card {
    width: 41vw;
    min-height: 80%;
  }

  .gallery-products {
    margin: 0.60em;
  }
}

@media only screen and (max-width: 463px) {

  .product-name {
    font-size: 16px;
  }

  .product-price {
    font-size: 14px;
  }


  figcaption {
    font-size: 14px;
  }

  .promo-title {
    max-width: 80%;
    line-height: 35px;
    font-size: 28px;
  }

  .busines-title-underline {
    width: 250px;
  }

  .business-title {
    font-size: 24px;
  }

  .cart-item-info {
    margin: 0;
  }

  .cart-item-info h4 {
    font-size: 21px;
  }

  .hidden-price {
    font-weight: var(--font-weight-semibold);
  }

  .cart-boxes-container {
    padding: 0.8em;
  }

  .featured-product-card {
    width: 90%;
    margin: 0.75em;
    padding: 0.6em 0;
  }
  
  .flex-cantidad {
    margin: 0;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .message-container {
    width: 80%;
  }
}

@media only screen and (min-width: 464px) and (max-width: 767px) {
  .intro-title {
    font-size: 48px;
  } 

  .los {
    font-size: 40px;
  }

  .mobile-header-icons {
    width: 35px;
    height: 35px;
  }

  .mobile-menu-icon {
    width: 50px;
    height: 50px;
  }

  .mobile-scroll-menu {
    width: 40px;
    height: 40px;
  }
  
  .align-logo-center {
    width: 70px;
    height: 70px;
  }

  .categories-mobile-circle {
    width: 80px;
    height: 80px;
  }

  .categories-mobile-img{
    width: 50px;
    height: 50px;
  }

  .categories-mobile-text {
    font-size: 14px;
  }

  .featured-product-card {
    height: 245px;
  }

  
  .promo-title {
    max-width: 90%;
    line-height: 35px;
    font-size: 36px;
  }

  .first-slider-card {
    width: 180px;
    height: 250px;
  }

  .underline {
    height: auto;
    width: 150px;
    left: -14%;
  }

  .big-title {
    font-size: 32px;
  }

  .quotes-container {
    position: absolute;
    left: 3em;
  }

  .business-frase {
    font-size: 20px;
  }

  .featured-product-card {
    min-height: 260px;
  }

  .products-gallery-card {
    width: 41vw;
    min-height: 80%;
  }

  .featured-product-card {
    width: 80%;
    margin: 0.75em;
    padding: 0.6em 0;
  }

  .message-container {
    width: 75%;
  }
  
}

@media only screen  and (max-width: 767px) {

  .logo-header-container {
    width: 70px;
    height: 70px;
  }

  .badges-flex {
    flex-direction: column;
    align-items: center;
    gap: 10em;
  }

  
  .title-container {
    left: 0;
  }

  .footer-top-publicity, .footer-top-publicity::before {
    height: 350px;
  }

  .sidebar-search {
    padding: 80px 0;
    width: 75%;
    gap: 3em;
  }
  
  .sidebar-menu {
    padding: 80px 0;
    width: 70%;
    gap: 4em;
  }

  .purchase-box {
    border: none;
    width: 95%;
    max-width: 95%;
    padding: 0;
  }

  .single-product-img img {
    width: calc(100% - 30px);
    aspect-ratio: 1 / 1;
  }

  .cart-items-container button.purchase-btn {
    width: 85%;
  }

  h4.hidden-price {
    display: block;
    font-weight: var(--font-weight-semibold);
  }

  .cart-item-info p, .cart-item-price {
    display: none;
  }

  .products-flex {
    width: 95%;
  }

  .flex-cantidad {
    margin: 0;
  }

  .cart-box-price-label {
    display: none;
  }

  .products-flex {
    grid-template-columns: repeat(2, 1fr);
  }
}



@media only screen and (min-width: 768px) {
  .cart-box-price-label {
    display: block;
  }
}

@media only screen and (min-width:768px) and (max-width: 1023px) { 
  .mobile-header-icons {
    width: 45px;
    height: 45px;
  }

  .mobile-menu-icon {
    width: 70px;
    height: 70px;
  }

  .mobile-scroll-menu {
    width: 50px;
    height: 50px;
  }

  .footer-logo {
    width: 250px;
  }

  .sidebar-search {
    padding: 80px 0;
    width: 65%;
    gap: 3em;
  }
  
  .sidebar-menu {
    padding: 80px 0;
    width: 50%;
    gap: 4em;
  }

  .first-slider-card {
    width: 185px;
    height: 245px;
  }

  .big-title {
    max-width: 65%;
  }

  .business-frase {
    max-width: 65%;
  }

  .quotes-container {
    position: absolute;
    left: calc(0% + 10vw);
  }
  
  .featured-product-card {
    height: 250px;
    width: 26vw;
    max-width: 230px;
    padding: 1em 0;
  }

  .products-flex {
    width: 90%;
  }

  .purchase-box {
    border: none;
    width: 100%;
    max-width: 60%;
    gap: 20px;
  }

  .single-product-img img {
    width: calc(100% - 100px);
    height: calc(100% - 100px);
  }

  .cart-items-container button.purchase-btn {
    width: 50%;
  }

  .products-gallery-card {
    width: 25vw;
    height: fit-content;
    min-height: 84%;
    padding: 0.6em 0;
  }

  #desktop-gr {
    position: relative;
    left: -30%; 
  }

  .products-flex {
    grid-template-columns: repeat(3, 1fr);
  }

  .message-container {
    width: 50%;
  }

}

@media only screen and (max-width: 1023px) {
  .left-links-width, .right-links, .categories-container, .checkout-cart-box {
    display: none;
  }

  .mobile-show, .categories-mobile-container, .cart-items-container .purchase-btn {
    display: block
  }

  .align-logo-center {
    position: static;
  }

  .content-box-intro {
    left: 0px;
    margin: 0 auto;
  }

  .header-align {
    padding: 10px 25px;
  }

  .flex-footer {
    flex-direction: column;
    gap: 30px;
  }

  .footer-logo {
    margin: 0 auto;
    width: fit-content;
  }

  div.hide-desktop {
    display: flex;
  }

  .badge-relative-box {
    width: 210px;
    height: 245px;
  }

  .business-badge-img {
    width: 191px;
    height: 191px;
    top: -95.5px;
  }

  .promotion-container {
    width: 100%;
    border-radius: 0;
    padding: 50px 0 ;
  }

  .promotion-content-container {
    gap: 12px;
    align-items: center;
  }

  .promo-title, .promo-paragraph {
    text-align: center;
  }

  .padding-menu {
    padding: 0;
  }

  .slider-container {
    width: 85%;
  }

  .single-product-page {
    flex-direction: column;
    align-items: center;

  }

  .single-product-img  {
    width: 100vw;
    height: 100vw;
  }

  .single-product-info {
    width: 95%;
    padding: 0;
  }

  .purchase-box-container {
    width: 100%;
    flex: none;
    margin: 1em 0;
  }

  #mobile-gr {
    display: block;
    width: 95%;
  }

  .purchase-caption {
    font-size: 18px;
    font-weight: var(--font-weight-semibold);
    margin: 0 0 10px;
  }

  .price-numbers {
    font-size: 20px;
    font-weight: var(--font-weight-semibold);
  }

  .cantidad {
    height: 40px;
  }

  .menos-btn, .mas-btn {
    width: 42px;
    height: 42px;
  }

  .cart-btn, .purchase-btn {
    font-size: 20px;
    padding: 15px 20px;
  }

  .buttons-container {
    gap: 15px;
    margin: 20px 0;
  }

  .price-description {
    font-size: 18px;
  }

  .price-align {
    gap: 10px;
  }

  .cart-items-total {
    justify-content: center;
  }

  .cart-items-container button.purchase-btn {
    margin: 0 auto;
    padding: 12.5px;
    margin-top: 0.75em;
  }

  .cart-item-info p {
    -webkit-line-clamp: 3;
  }

  .footer-logo {
    width: 80%;
    max-width: 450px;
  }
}

@media only screen and (max-width: 1100px) {
  .footer {
    padding: 60px 40px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .products-flex {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (min-width: 1440px) {
  .products-flex {
    grid-template-columns: repeat(5, 1fr);
  }
}